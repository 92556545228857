<template>
  <el-container>
    <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="handleSelectionChange"
      :buttonConfig="buttonConfig" @handleButtonClick="callMethod">
    </grid-table>
    <edit-dialog ref="editDialog" v-if="dialogVisible" :formConfig="editFormConfig"
      @handleQuery="handleQuery"></edit-dialog>
    <install-payment-type ref="installDialog" v-if="installDialogVisible" :formConfig="editFormConfig"
      @handleQuery="handleQuery"></install-payment-type>
    <payment-bank ref="paymentBankDialog" v-if="paymentBankDialogVisible"></payment-bank>
  </el-container>
</template>

<script>
import { submit } from "@/api/base";
import InstallPaymentType from "./install.vue";
import PaymentBank from "./bank/index.vue";
export default {
  name: "payType",
  components: {
    InstallPaymentType,
    PaymentBank,
  },
  data() {
    return {
      routeParam: {},
      buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
      tableConfig: { ...(this.$route.meta.pageConfig.table || {}) },
      selectRows: [],
      dialogVisible: false,
      installDialogVisible: false,
      paymentBankDialogVisible: false,
      editFormConfig: {},
    };
  },
  mounted() {
    this.routeParam = {
      ...(this.$route.query || {}),
      ...(this.$route.params || {}),
    };
  },
  activated() {
    var param = {
      ...(this.$route.query || {}),
      ...(this.$route.params || {}),
    };
    if (Object.keys(param).length > 0 && this.routeParam != param) {
      this.routeParam = param;
    }
  },
  watch: {
    routeParam: {
      deep: true,
      handler() {
        this.handleQuery();
      },
    },
  },
  methods: {
    handleQuery() {
      this.$refs.gridTable.fetchData(false, null, this.routeParam);
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    callMethod(button) {
      let methods = this.$options.methods;
      const _this = this;
      methods[button.method](_this, button);
    },
    handleSearch(_this, button) {
      _this.handleQuery();
    },
    handleInstall(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (!button.isMultiSelectRow && _this.selectRows.length > 1) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      _this.installDialogVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.installDialog.init(button.label);
      });
    },
    handleConfig(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage("请选择数据", "error");
        return false;
      }
      if (!button.isMultiSelectRow && _this.selectRows.length > 1) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      if (_this.selectRows[0].PaymentName == "转账支付") {
        _this.paymentBankDialogVisible = true;
        _this.$nextTick(() => {
          _this.$refs.paymentBankDialog.init();
        });
      } else {
        _this.dialogVisible = true;
        _this.$nextTick(() => {
          _this.editFormConfig = { ...button.bindForm };
          _this.$refs.editDialog.init(
            button.label,
            _this.getTableParam(
              _this.selectRows,
              button.isNeedRowKey,
              button.isMultiSelectRow,
              _this.tableConfig.rowKey,
              button.paramJson
            )
          );
        });
      }
    },
    handleToExcute(_this, button) {
      if (
        button.isNeedSelectRow &&
        (!_this.selectRows || _this.selectRows.length == 0)
      ) {
        _this.$baseMessage(`请选择要${button.label}的数据`, "error");
        return false;
      }
      if (
        button.isNeedSelectRow &&
        !button.isMultiSelectRow &&
        _this.selectRows.length > 1
      ) {
        _this.$baseMessage("只能选择一行", "error");
        return false;
      }
      if (button.isNeedConfirm) {
        _this.$baseConfirm(`确定选中项要${button.label}吗？`, null, () => {
          this.handleExecute(_this, button);
        });
      } else {
        this.handleExecute(_this, button);
      }
    },
    async handleExecute(_this, button) {
      const { msg } = await submit(
        button.url,
        _this.getTableParam(
          _this.selectRows,
          button.isNeedRowKey,
          button.isMultiSelectRow,
          _this.tableConfig.rowKey,
          button.paramJson
        )
      );
      if (msg) {
        _this.$baseMessageBox(
          `${button.label}成功，${msg}`,
          `${button.label}提示`
        );
      } else {
        _this.$baseMessage(`${button.label}成功`, "success");
      }
      _this.handleQuery();
    },
  },
};
</script>
